<div fxLayout="row" fxLayoutAlign="center center" *ngIf="widgetInputs$ | async; let inputs">
  <mat-card
    @fadeIn
    [fxFlex]="inputs.styles.width"
    fxFlex.lt-md="100"
    [ngClass]="{ 'no-boxshodow': !inputs.settings!.boxShodow }"
    ngClass.lt-md="m-0 p-0"
  >
    <mat-card-content [ngClass]="{ 'p-0': !inputs.settings!.cardPadding }">
      <div fxLayout="row wrap" class="m-b-20" *ngIf="inputs!.settings!.showTitle || inputs!.settings!.showLogo">
        <img
          *ngIf="inputs.settings!.showLogo"
          src="{{ inputs.settings!.logoUrl }}"
          width="40"
          alt="homepage"
          class="light-logo m-r-20"
        />
        <h2 class="m-0" *ngIf="inputs.settings!.showTitle">{{ inputs.settings!.title }}</h2>
      </div>
      <div *ngIf="inputs.he_pid && inputs.he_uaid">
        <div *ngIf="inputs!.settings!.mode === 'full'">
          <app-full-mode (callback)="appointmentCallback($event)"></app-full-mode>
        </div>
        <div *ngIf="inputs!.settings!.mode === 'new'">
          <app-new-mode (callback)="appointmentCallback($event)"></app-new-mode>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
<app-network-connection></app-network-connection>
