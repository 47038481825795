import * as userActions from './user.actions';
import { IUser, User } from './user.model';
import { ActionReducer } from '@ngrx/store';
import { AppState } from '../state';

export type Action = userActions.All;

const defaultUser = new User('', 'GUEST');

/**
 * Define all store queries for Post(s)
 */
export const UsersQuery = {
  getUser: (state: AppState) => state.user,
  isAuthenticated: (state: AppState) => state.user.authenticated,
  isLoading: (state: AppState) => state.user.loading,
  getUid: (state: AppState) => state.user.uid,
};

/// Reducer function
export const userReducer: ActionReducer<IUser, Action> = (state = defaultUser, action) => {
  switch (action.type) {
    case userActions.AUTHENTICATED:
      return { ...state, ...action.payload, loading: false, authenticated: true };

    case userActions.NOT_AUTHENTICATED:
      return { ...state, ...defaultUser, loading: false, authenticated: false };

    case userActions.AUTH_ERROR:
      return { ...state, ...action.payload, loading: false, authenticated: false };

    case userActions.LOGOUT:
      return { ...state, loading: true };

    case userActions.STOP_LOADER:
      return { ...state, loading: false };

    default:
      return state;
  }
};
