import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchHttpError, defaultHttpOptions } from '../util/http';
import { Appointment } from '../state/appointment';
const mapToAppointment = map((r) => <Appointment>r);

@Injectable({
  providedIn: 'root',
})
export class AppointmentService {
  constructor(private http: HttpClient) {}

  create(appointment: Appointment): Observable<Appointment> {
    const url = `${environment.services.appointment}/appointment`;
    return this.http.post(url, appointment, defaultHttpOptions).pipe(mapToAppointment, catchHttpError);
  }

  get(appointmentId: string): Observable<Appointment> {
    const url = `${environment.services.appointment}/appointment/${appointmentId}`;
    return this.http.get(url, defaultHttpOptions).pipe(mapToAppointment, catchHttpError);
  }

  update(appointment: Partial<Appointment>): Observable<Appointment> {
    const url = `${environment.services.appointment}/appointment/${appointment.id}`;
    return this.http.put(url, appointment, defaultHttpOptions).pipe(mapToAppointment, catchHttpError);
  }
}
