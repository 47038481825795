import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  title:{text: any};
  subTitle?: {text: any};
  cancelBtn: {text: any};
  okBtn: {text: any};
}
/**
 * This popup is used to show the confirmation message. it takes the following inputs
 * 
 * title object
 * 
 * subtitle object
 * 
 * okBtn object
 * 
 * cancelBtn object
 */
@Component({
  selector: 'app-confirm-popup',
  templateUrl: './confirm-popup.component.html',
  styleUrls: ['./confirm-popup.component.scss']
})
export class ConfirmPopupComponent { 

  constructor(
    public dialogRef: MatDialogRef<ConfirmPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public popup: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
