<div *ngIf="!selectedAppointment" class="appointment-list-container">
  <mat-accordion class="accordion" *ngIf="(appointments | async)?.length && (isLoading$ | async) === false">
    <div fxLayout="row wrap" *ngFor="let appointment of appointments | async">
      <mat-expansion-panel
        [ngClass]="{
          cancelled: appointment.status === 'cancelled',
          'past-booking': DateTime.fromISO(appointment!.start!) < DateTime.now() && appointment.status === 'booked',
          booked: DateTime.fromISO(appointment!.start!) >= DateTime.now() && appointment.status === 'booked'
        }"
        fxFlex="50vw"
        fxFlex.lt-sm="87vw"
        @fadeIn
      >
        <mat-expansion-panel-header ngClass.lt-sm="panel-header">
          <mat-panel-title class="panel-title">
            <div class="expansion-panel-title">
              <span *ngIf="appointment.schedule && appointment.schedule.scheduleName"
                >{{ appointment.schedule.scheduleName }} -
              </span>
              {{ DateTime.fromISO(appointment.start!).toFormat('DDDD (t)') }}
            </div>
            <div *ngIf="appointment.status === 'cancelled'" class="m-l-20">Cancelled</div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <h4 class="m-t-0" *ngIf="appointment.description">{{ appointment.description }}</h4>
          <h4 class="m-t-0" *ngIf="!appointment.description && appointment.schedule && appointment.schedule.comment">
            {{ appointment.schedule.comment }}
          </h4>
          <div fxLayout="row wrap">
            <button
              class="m-r-20"
              mat-raised-button
              color="primary"
              (click)="rescheduleAppointment(appointment)"
              [disabled]="DateTime.fromISO(appointment!.start!) < DateTime.now() || appointment.status === 'cancelled'"
            >
              Reschedule Appointment
            </button>
            <button
              mat-raised-button
              color="warn"
              (click)="cancelAppointmentPopup(appointment)"
              [disabled]="DateTime.fromISO(appointment.start!) < DateTime.now() || appointment.status === 'cancelled'"
            >
              Cancel Appointment
            </button>
          </div>
        </ng-template>
      </mat-expansion-panel>
    </div>
  </mat-accordion>
  <div *ngIf="!(appointments | async)?.length && (isLoading$ | async) === false">
    <h1>No appointments scheduled</h1>
  </div>
  <div *ngIf="isLoading$ | async">
    <div class="m-b-5" *ngFor="let item of [].constructor(8)">
      <div class="skeleton-loader" fxFlex="50vw" fxFlex.lt-sm="87vw" style="height: 48px"></div>
    </div>
  </div>
</div>
<div @fadeIn *ngIf="selectedAppointment && selectedAppointment.id">
  <div>
    <button mat-icon-button (click)="onBack()">
      <mat-icon class="m-r-10">keyboard_arrow_left</mat-icon>
      Back
    </button>
  </div>
  <div>
    <h2>Reschedule Appointment ({{ DateTime.fromISO(selectedAppointment.start!).toFormat('DDDD (t)') }})</h2>
    <app-appointment (callback)="appointmentCallback($event)"></app-appointment>
  </div>
</div>
