import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { FlexLayoutModule } from '@angular/flex-layout';

import { environment } from '../environments/environment';

import { AppointmentListComponent } from './components/appointment-list/appointment-list.component';
import { ScheduleComponent } from './components/schedule/schedule.component';
import { AppointmentComponent } from './components/appointment/appointment.component';
import { ConfirmPopupComponent } from './components/confirm-popup/confirm-popup.component';
import { ToastrModule } from 'ngx-toastr';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { getScope } from './config';
import { AppStateModule } from './state/state.module';
import { FormsModule } from '@angular/forms';
import { CacheLocation } from '@auth0/auth0-spa-js';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { NewModeComponent } from './components/new-mode/new-mode.component';
import { FullModeComponent } from './components/full-mode/full-mode.component';
import { NetworkConnectionComponent } from './components/network-connection/network-connection.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppointmentConfirmComponent } from './components/appointment-confirm/appointment-confirm.component';
@NgModule({
  declarations: [
    AppComponent,
    AppointmentListComponent,
    ScheduleComponent,
    AppointmentComponent,
    ConfirmPopupComponent,
    NewModeComponent,
    FullModeComponent,
    NetworkConnectionComponent,
    AppointmentConfirmComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    MaterialModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    FormsModule,
    AppStateModule,
    AuthModule.forRoot(
      Object.assign(
        {
          scope: `openid email ${getScope()}`,
          cacheLocation: 'localstorage' as CacheLocation,
          skipRedirectCallback: false,
          responseType: 'code',
          httpInterceptor: {
            allowedList: [`${environment.services.appointment}/appointment/*`],
          },
        },
        environment.auth0Config,
        window.auth0Config
      )
    ),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true }],
  entryComponents: [AppComponent, ConfirmPopupComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
