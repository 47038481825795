import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, map, mergeMap, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { Store } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import * as appointmentActions from './appointment.actions';
import { Appointment } from './appointment.model';
import { AppState } from '../state';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { randomId } from '../../util/state';
import { AppointmentService } from '../../services/appointment.service';
import pickBy from 'lodash-es/pickBy';

type Action = appointmentActions.All;

export const AppointmentsQuery = {
  isLoading: (state: AppState) => state.appointment.loading,
  error: (state: AppState) => state.appointment.error,
};

@Injectable()
export class AppointmentEffects {
  error$ = this.store.select(AppointmentsQuery.error);
  isLoading$ = this.store.select(AppointmentsQuery.isLoading);

  private cancelQueryState$ = new Subject<void>();
  private cancelQueryObservable$ = this.cancelQueryState$.asObservable();
  private queryState$ = new Subject<boolean>();
  private queryObservable$ = this.queryState$.asObservable();
  query$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<appointmentActions.Query>(appointmentActions.QUERY),
      tap(() => this.cancelQueryState$.next()), // cancel any queries in progress
      switchMap((query) => {
        return this.db
          .collection<Appointment>('Appointment', (f: firebase.firestore.Query) => {
            if (query.patientId) {
              f = f.where('patient', 'array-contains', query.patientId);
            }
            return f.orderBy('start', 'desc');
          })
          .stateChanges()
          .pipe(
            tap(() => {
              this.store.dispatch(new appointmentActions.StopLoader());
              return this.queryState$.next(true);
            }),
            takeUntil(this.cancelQueryObservable$.pipe(tap(() => this.queryState$.next(false))))
          );
      }),
      mergeMap((d) => d),
      map((action) => {
        return {
          type: `[Appointment] ${action.type}`,
          appointment: {
            ...action.payload.doc.data(),
          },
        } as Action;
      }),
      catchError((err) => of(new appointmentActions.AppointmentError(err)))
    )
  );
  cancelQuery$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(appointmentActions.CANCEL_QUERY),
        // tap(() => this.queryComponent$.next()), // do we need to trigger one value?
        switchMap(() => this.queryObservable$.pipe(debounceTime(environment.firestore.unsubscribeDelay))),
        take(1),
        tap(() => this.cancelQueryState$.next())
      ),
    {
      dispatch: false,
    }
  );
  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appointmentActions.CREATE),
      map((action: appointmentActions.Create) => action.appointment),
      switchMap((toCreate) =>
        this.service.create(pickBy(toCreate, (value, key) => key !== 'id') as Appointment).pipe(
          map((appointment) => new appointmentActions.CreateSuccess(toCreate, appointment)),
          catchError((err) => of(new appointmentActions.CreateFail(toCreate, err)))
        )
      )
    )
  );
  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appointmentActions.UPDATE),
      map((action: appointmentActions.Update) => action.appointment),
      switchMap((toUpdate) =>
        this.service.update(toUpdate).pipe(
          map((appointment) => new appointmentActions.UpdateSuccess(appointment)),
          catchError((err) => of(new appointmentActions.UpdateFail(err)))
        )
      )
    )
  );

  public cancelQuery() {
    this.store.dispatch(new appointmentActions.CancelQuery());
  }

  public query(patientId?: string) {
    this.store.dispatch(new appointmentActions.Query(patientId));
  }

  public select(appointment: Appointment | null) {
    this.store.dispatch(new appointmentActions.Select(appointment));
  }

  public create(appointment: Appointment) {
    appointment.id = randomId(); // assign a random temporary ID
    this.store.dispatch(new appointmentActions.Create(appointment));
  }

  public update(appointment: Partial<Appointment>) {
    if (!appointment.id) {
      this.store.dispatch(new appointmentActions.UpdateFail('No appointment ID provided'));
    } else {
      this.store.dispatch(new appointmentActions.Update(appointment));
    }
  }

  constructor(
    private actions$: Actions,
    private db: AngularFirestore,
    private store: Store<AppState>,
    private service: AppointmentService
  ) {}
}
