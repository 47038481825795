import { initialState } from './widget.model';
import { ADDED, All, MODIFIED, SELECT } from './widget.actions';

export function widgetInputReducer(state = initialState, action: All) {
  switch (action.type) {
    case ADDED:
      return { ...state, ...action.payload };
    case MODIFIED:
      return { ...state, ...action.payload };
    case SELECT:
      return {
        ...state,
      };
    default:
      return state;
  }
}
