<div @fadeIn *ngIf="!(appointmentSkeleton | async) && !(appointmentEffects.isLoading$ | async)">
  <div fxLayout="row wrap" class="m-t-20">
    <div fxFlex="37" fxHide.lt-sm>
      <mat-calendar
        #calendar
        class="calendar"
        [selected]="selectedDate || DateTime.now()"
        [minDate]="minDate!"
        [maxDate]="maxDate!"
        [dateFilter]="calFilter"
        (selectedChange)="onSelectCalendarDate($event)"
      >
      </mat-calendar>
    </div>
    <div [ngClass.gt-xs]="{ 'm-l-20': true }" [ngClass.lt-sm]="{ 'm-l-0': true }" fxFlex>
      <div class="m-b-10" fxLayout="row" fxLayoutAlign="space-between center">
        <div fxHide.gt-xs>
          <input
            fxHide
            matInput
            [matDatepicker]="picker"
            [(ngModel)]="selectedDate"
            [min]="minDate!"
            [max]="maxDate!"
            [matDatepickerFilter]="calFilter"
            (dateChange)="onSelectCalendarDate($event)"
          />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </div>
        <h2 class="m-0">{{ selectedDate && selectedDate?.toLocaleString(DateTime.DATE_MED) }}</h2>
        <div>
          <button
            [disabled]="previousDateBtn"
            class="button-bg m-r-5"
            mat-icon-button
            (click)="previousDate(selectedDate!)"
            [ngClass.lt-md]="{ 'small-button': true }"
          >
            <mat-icon>chevron_left</mat-icon>
          </button>
          <button
            [disabled]="nextDateBtn"
            class="button-bg"
            mat-icon-button
            (click)="nextDate(selectedDate!)"
            [ngClass.lt-md]="{ 'small-button': true }"
          >
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
      <div
        *ngIf="sortedSlots.length"
        fxLayout="row wrap"
        [ngClass.gt-sm]="{ 'slot-container': true }"
        [ngClass.lt-md]="{ 'slot-container-full': true }"
        fxLayoutAlign="space-between start"
      >
        <button
          disableRipple="true"
          *ngFor="let slot of sortedSlots"
          class="m-b-10"
          fxFlex="31"
          [ngClass]="{ available: slot.availableCapacity > 0, selected: slot.selected }"
          [disabled]="slot.availableCapacity === 0"
          (click)="onSelectSlot(slot)"
          mat-stroked-button
        >
          {{ DateTime.fromISO(slot.start).toLocaleString(DateTime.TIME_SIMPLE) }}
        </button>
        <div fxFlex="31" *ngIf="sortedSlots.length % 3 == 2"></div>
      </div>
      <div *ngIf="!sortedSlots.length" class="slot-container">
        <div fxLayout="row wrap" fxLayoutAlign="center center">
          <img width="150" src="{{ imgBaseUrl }}no-slots.png" />
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="center center">
          <h4 class="m-b-0">No slots available</h4>
        </div>
        <!-- <div fxLayout="row wrap" fxLayoutAlign="center center" *ngIf="nextAvailableDate(selectedDate!)">
          <p class="pointer blue" (click)="nextDate(selectedDate!)">
            Next available slots ({{ nextAvailableDate(selectedDate!)!.toLocaleString(DateTime.DATE_MED) }})
          </p>
        </div> -->
      </div>
    </div>
  </div>
  <div fxLayout="row wrap" [ngClass.gt-xs]="{ 'm-t-20': true }" fxLayoutAlign="space-between center">
    <div fxFlex.lt-sm="100" [ngClass.lt-sm]="{ 'm-b-20': true }" fxLayoutAlign="center center">
      <mat-icon class="m-r-5">watch_later</mat-icon>
      {{ timeZone }}
    </div>
    <button
      class="m-l-20"
      fxFlex.lt-sm="100"
      fxFlex="20"
      [disabled]="isSubmitted || !selectedSlot"
      mat-raised-button
      color="primary"
      (click)="bookAppointment()"
    >
      Book now
    </button>
  </div>
</div>

<div *ngIf="(appointmentSkeleton | async) || (appointmentEffects.isLoading$ | async)">
  <div fxLayout="row wrap" class="m-t-20">
    <div fxFlex="40" fxHide.lt-sm>
      <div class="skeleton-loader" style="height: 390px"></div>
    </div>
    <div [ngClass.gt-sm]="{ 'm-l-20': true }" fxFlex>
      <div class="m-b-10" fxLayout="row" fxLayoutAlign="space-between center">
        <div fxFlex="40">
          <div class="skeleton-loader" style="height: 40px"></div>
        </div>
        <div fxFlex="20">
          <div class="skeleton-loader" style="height: 40px"></div>
        </div>
      </div>
      <div
        fxLayout="row wrap"
        [ngClass.lt-md]="{ 'slot-container-full': true }"
        style="padding: 0"
        fxLayoutAlign="space-between start"
      >
        <div fxFlex="31" class="m-b-10" *ngFor="let item of fakeSlots">
          <div class="skeleton-loader" style="height: 40px"></div>
        </div>
      </div>
    </div>
  </div>
  <div fxLayout="row wrap" [ngClass.gt-xs]="{ 'm-t-20': true }" fxLayoutAlign="space-between center">
    <div fxFlex.lt-sm="100" fxFlex="30" [ngClass.lt-sm]="{ 'm-b-20': true }">
      <div class="skeleton-loader" style="height: 40px"></div>
    </div>
    <div fxFlex.lt-sm="100" fxFlex="20">
      <div class="skeleton-loader" style="height: 40px"></div>
    </div>
  </div>
</div>
<!--Appointment Screen-->
