import { Component } from '@angular/core';
import { Observable, merge, fromEvent, of } from 'rxjs';
import { mapTo } from 'rxjs/operators';

@Component({
  selector: 'app-network-connection',
  templateUrl: './network-connection.component.html',
  styleUrls: ['./network-connection.component.scss'],
})
export class NetworkConnectionComponent {
  online$: Observable<boolean>;

  constructor() {
    this.networkConection();
  }

  networkConection() {
    this.online$ = merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(false))
    );
  }
}
