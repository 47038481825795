<div class="container" fxLayoutAlign="center center" @fadeIn>
  <div>
    <span class="title">Thank You!</span>
    <div class="icon-container">
      <mat-icon class="icon">done</mat-icon>
    </div>
    <div class="content m-t-20 m-b-20">Your appointment is booked</div>
    <div fxLayoutAlign="center center">
      <button color="primary" mat-raised-button (click)="callback.emit()">View Appointment</button>
    </div>
  </div>
</div>
