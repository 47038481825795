<mat-radio-group color="primary">
  <mat-radio-button
    fxLayout="row wrap"
    class="m-t-20"
    [value]="item.id"
    *ngFor="let item of allSchedules | async"
    (change)="onSelect(item)"
    >{{ item.scheduleName }}</mat-radio-button
  >
</mat-radio-group>
