import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Schedule, selectAllSchedules } from '../../state/schedules';
import { Store } from '@ngrx/store';
import { AppState } from '../../state/state';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss'],
})
export class ScheduleComponent implements OnInit {
  @Output() public scheduleSelected = new EventEmitter();

  allSchedules: Observable<Schedule[]>;

  selectedScheduleId?: string;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.allSchedules = this.store.select(selectAllSchedules);
  }

  onSelect(schedule: Schedule) {
    this.selectedScheduleId = schedule.id;
    this.scheduleSelected.emit(schedule);
  }
}
