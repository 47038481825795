import { Component, OnInit, Input, ViewChild, OnDestroy, EventEmitter, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MatCalendar, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { ToastrService } from 'ngx-toastr';
import { Slot, Schedule } from '@carecognitics/fhir-resources';
import { DateTime } from 'luxon';
import { FADE_IN } from '../../util/animations/animation';
import groupBy from 'lodash-es/groupBy';
import sortBy from 'lodash-es/sortBy';
import { getSelectedSchedule } from '../../state/schedules';
import { SlotEffects, selectAllSlots, SlotQuery } from '../../state/slots';
import { Store } from '@ngrx/store';
import { AppState } from '../../state/state';
import { Subscription, Observable } from 'rxjs';
import { take, debounceTime, filter, tap, withLatestFrom } from 'rxjs/operators';
import { AppointmentEffects, StopLoader } from '../../state/appointment';
const ONE_DAY_DURATION = { day: 1 };
const DEBOUNCE_TIME = 2000;
@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.scss'],
  animations: [FADE_IN],
})
export class AppointmentComponent implements OnInit, OnDestroy {
  appointmentSkeleton: Observable<boolean>;
  calFilter: any;
  imgBaseUrl = environment.tenant.imageBaseUrl;
  slotSubscription: Subscription;
  selectedSchedule: Schedule;

  @Input() isFullscreen = false;
  @Output() callback = new EventEmitter();

  DateTime = DateTime;
  sortedSlots: (Slot & { selected?: boolean; available?: boolean })[] = [];
  selectedSlot: Slot | null;
  isSubmitted = false;
  timeZone: string;

  slotsSubscription: Subscription;
  selectedScheduleSubscription: Subscription;
  appointmentSuccessSubscription: Subscription;
  appointmentErrorSubscription: Subscription;
  @ViewChild('calendar', { static: false }) calendar: MatCalendar<DateTime>;

  minDate?: DateTime;
  maxDate?: DateTime;
  selectedDate?: DateTime | null;
  workingDays: string[] = [];
  nextDateBtn = false;
  previousDateBtn = false;
  allSlots?: { [date: string]: (Slot & { available?: boolean })[] };
  fakeSlots = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21];
  constructor(
    private toastr: ToastrService,
    private store: Store<AppState>,
    private slotEffects: SlotEffects,
    public appointmentEffects: AppointmentEffects
  ) {
    this.appointmentSkeleton = this.store.select(SlotQuery.isLoading);
    this.timeZone = DateTime.local().zoneName;
    this.selectedScheduleSubscription = this.store
      .select(getSelectedSchedule)
      .pipe(take(1))
      .subscribe((schedule) => {
        this.selectedSchedule = schedule!;
        if (this.selectedSchedule && this.selectedSchedule.id) {
          this.slotEffects.query(this.selectedSchedule.id);
        }
      });
    this.appointmentErrorSubscription = appointmentEffects.error$
      .pipe(
        filter((e) => !!e && this.isSubmitted),
        tap((error) => {
          this.isSubmitted = false;
          this.callback.emit({ type: 'appointment-error', error });
          this.toastr.warning((error as any).message || error?.toString() || 'Unknown error', 'Appointment', {
            progressBar: true,
            timeOut: 3000,
          });
        })
      )
      .subscribe();

    this.appointmentSuccessSubscription = appointmentEffects.isLoading$
      .pipe(
        filter((loading) => !loading && this.isSubmitted),
        withLatestFrom(appointmentEffects.error$),
        filter(([, error]) => !error),
        tap(() => {
          this.isSubmitted = false;
          this.callback.emit({ type: 'appointment-success' });
          this.toastr.success('Appointment booked successfully');
        })
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.getSlotData();
  }

  ngOnDestroy() {
    this.slotsSubscription.unsubscribe();
    this.selectedScheduleSubscription.unsubscribe();
    this.slotEffects.cancelQuery();
    this.appointmentSuccessSubscription.unsubscribe();
    this.appointmentErrorSubscription.unsubscribe();
    this.store.dispatch(new StopLoader());
  }

  previousDate(date: DateTime) {
    date = date.minus({ days: 1 });
    const index = this.workingDays.findIndex((x) => x === date.toFormat('yyyy-MM-dd'));
    const dateDiff = date.diff(this.minDate!, 'days').days;
    if (index !== -1 && dateDiff >= 0) {
      this.selectedDate = date;
      this.setCalendarDate();
      this.enablePreviousBtn(date);
      this.enableNextBtn(date);
      this.onSelectSlot(null);
      this.formatSlotData();
    } else if (dateDiff > 0) this.previousDate(date);
  }

  enablePreviousBtn(date: DateTime) {
    const dateDiff = date.diff(this.minDate!, 'days').days;
    this.previousDateBtn = dateDiff === 0;
  }

  nextDate(date: DateTime) {
    if (this.minDate) {
      date = date.plus({ days: 1 });
      const index = this.workingDays.findIndex((x) => x === date.toFormat('yyyy-MM-dd'));
      const dateDiff = date.diff(this.minDate, 'days').days;
      if (index !== -1 && dateDiff >= 0) {
        this.selectedDate = date;
        this.setCalendarDate();
        this.enableNextBtn(date);
        this.enablePreviousBtn(date);
        this.onSelectSlot(null);
        this.formatSlotData();
      } else if (dateDiff >= 0) this.nextDate(date);
    }
  }

  nextAvailableDate(date: DateTime): DateTime | undefined | boolean {
    if (date) {
      date = date.plus(ONE_DAY_DURATION);
      const dateFormatted = date.toISODate();
      const index = this.workingDays.findIndex((x) => x === dateFormatted);
      const dateDiff = date.diff(this.minDate!, 'days').days;
      if (index !== -1 && dateDiff >= 0) {
        return date;
      } else if (dateDiff > 0) {
        return this.nextAvailableDate(date);
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }

  enableNextBtn(date: DateTime) {
    const dateDiff = date.diff(this.maxDate!, ['days']).days;
    this.nextDateBtn = dateDiff === 0;
  }

  onSelectCalendarDate(event: MatDatepickerInputEvent<DateTime> | DateTime | null) {
    if (event) {
      if ('target' in event) {
        this.selectedDate = event.target.value;
      } else {
        this.selectedDate = event;
      }
    }
    this.formatSlotData();
  }

  setCalendarDate() {
    if (this.calendar && this.selectedDate) {
      this.calendar.activeDate = this.selectedDate;
      this.calendar.updateTodaysDate();
    }
  }

  onSelectSlot(slot: (Slot & { selected?: boolean; available?: boolean }) | null) {
    this.selectedSlot = slot && slot.selected ? null : slot;
    this.sortedSlots = this.sortedSlots.map((x) => {
      if (slot && slot.id === x.id) return Object.assign({}, x, { selected: !x.selected });
      else return Object.assign({}, x, { selected: false });
    });
  }

  calendarFilter() {
    this.calFilter = (d: DateTime | null): boolean => {
      return this.workingDays.findIndex((x) => d && d.toFormat('yyyy-MM-dd') === x) !== -1;
    };
  }

  getWorkingDays(start: DateTime, end: DateTime) {
    const includedDays: string[] = [];
    while (start.startOf('day') <= end.startOf('day')) {
      if (this.allSlots) {
        const slots = this.allSlots[start.toFormat('yyyy-MM-dd')];
        if (slots && slots.length) includedDays.push(start.toFormat('yyyy-MM-dd'));
      }
      start = start.plus({ days: 1 });
    }
    return includedDays;
  }

  getSlotData() {
    this.slotsSubscription = this.store
      .select(selectAllSlots)
      .pipe(debounceTime(DEBOUNCE_TIME))
      .subscribe((slots: Slot[]) => {
        this.allSlots = groupBy(slots, (item) => DateTime.fromISO(item.start).toISODate());
        const startDate = DateTime.fromISO(this.selectedSchedule.planningHorizon!.start!);
        const daysBetween = DateTime.now().diff(startDate, 'days').days;
        const minDt = daysBetween > 0 ? DateTime.now() : startDate;
        const maxDt = DateTime.fromISO(this.selectedSchedule.planningHorizon!.end!);
        this.workingDays = this.getWorkingDays(minDt, maxDt);
        if (this.workingDays.length) {
          this.minDate = DateTime.fromISO(this.workingDays[0]);
          this.maxDate = DateTime.fromISO(this.workingDays[this.workingDays.length - 1]);
          this.selectedDate = this.minDate;
          this.enablePreviousBtn(this.minDate);
          this.enableNextBtn(this.selectedDate);
        } else {
          this.minDate = minDt;
          this.maxDate = maxDt;
          this.selectedDate = this.minDate;
          this.enablePreviousBtn(this.minDate);
          this.enableNextBtn(this.selectedDate);
        }

        this.calendarFilter();
        this.setCalendarDate();
        this.formatSlotData();
      });
  }

  formatSlotData() {
    if (this.allSlots && this.selectedDate) {
      const key = this.selectedDate.toFormat('yyyy-MM-dd');
      let slots = this.allSlots[key] || [];
      const leadTime = DateTime.now().plus({ minutes: 60 }).valueOf();
      slots &&
        slots.map((x) => {
          const slotTime = DateTime.fromISO(x.start).valueOf();
          Object.assign({}, x, { available: slotTime >= leadTime && x.availableCapacity > 0 });
        });
      if (slots.length) slots = sortBy(slots, (slot) => slot.start.valueOf());

      this.sortedSlots = slots;
    }
  }

  bookAppointment() {
    const isSlotAvailable = this.sortedSlots.filter((x) => x.id === this.selectedSlot!.id && x.availableCapacity > 0);
    if (isSlotAvailable && isSlotAvailable.length) {
      this.callback.emit({ type: 'book-appointment', slot: this.selectedSlot });
      this.isSubmitted = true;
    } else {
      this.toastr.warning('The selected slot is not available, please select different slot.');
    }
  }
}
