<div class="container" fxLayoutAlign="center center" *ngIf="!(online$ | async)">
  <div>
    <div fxLayout="row" fxLayoutAlign="center center">
      <picture>
        <source type="image/svg+xml" srcset="assets/images/no-connection.svg" />
        <img src="assets/images/no-connection.png" class="img-no-connection" alt="No Internet Connection" />
      </picture>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
      <h1>No internet connection</h1>
    </div>
  </div>
</div>
