import { Component, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FADE_IN } from '../../util/animations/animation';
import { Store } from '@ngrx/store';
import { AppState } from '../../state/state';
import { UserEffects, Login } from '../../state/user';
import { Observable } from 'rxjs';
import { getWidgetInputs, WidgetInput } from 'src/app/state/widget';
import { AppointmentEffects } from '../../state/appointment';
import { ScheduleEffects } from 'src/app/state/schedules';

@Component({
  selector: 'app-full-mode',
  templateUrl: './full-mode.component.html',
  styleUrls: ['./full-mode.component.scss'],
  animations: [FADE_IN],
})
export class FullModeComponent implements OnDestroy {
  showListComponent = true;
  showAppointmentComponent = false;
  widgetInputs$: Observable<WidgetInput>;
  @Output() callback = new EventEmitter();

  constructor(
    private store: Store<AppState>,
    private AppointmentEffects: AppointmentEffects,
    public auth: UserEffects,
    private scheduleEffect: ScheduleEffects
  ) {
    this.scheduleEffect.query();
    this.widgetInputs$ = this.store.select(getWidgetInputs);
  }

  bookAppointment() {
    this.showListComponent = false;
    this.showAppointmentComponent = true;
  }

  onBack() {
    this.showListComponent = true;
    this.showAppointmentComponent = false;
  }

  ngAfterViewInit(): void {
    this.store.dispatch(new Login());
  }

  loginWithPopup() {
    this.auth.login();
  }

  logout() {
    this.auth.logout();
    this.AppointmentEffects.cancelQuery();
  }

  newModeCallback(event: any) {
    if (event && event.type === 'appointment-success') {
      this.onBack();
    }
    this.callback.emit(event);
  }

  ngOnDestroy() {
    this.AppointmentEffects.cancelQuery();
  }
}
