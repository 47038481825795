<div *ngIf="!showThankYouPage">
  <div ngClass.lt-md="no-padding" *ngIf="!h_sid || (h_sid && h_sid.length !== 1)">
    <mat-vertical-stepper *ngIf="!mobileQuery.matches" #stepper [selectedIndex]="stepIndex">
      <mat-step>
        <ng-template matStepLabel>
          {{ (selectedSchedule | async) ? (selectedSchedule | async)!.scheduleName : 'Select Schedule' }}
        </ng-template>
        <form>
          <app-schedule (scheduleSelected)="onSelectSchedule($event)"></app-schedule>
        </form>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Select Appointment</ng-template>
        <app-appointment (callback)="appointmentCallback($event)" *ngIf="stepIndex === 1"> </app-appointment>
      </mat-step>
    </mat-vertical-stepper>
    <mat-horizontal-stepper *ngIf="mobileQuery.matches" [selectedIndex]="stepIndex">
      <mat-step>
        <ng-template matStepLabel>
          {{ (selectedSchedule | async) ? (selectedSchedule | async)!.scheduleName : 'Select Schedule' }}
        </ng-template>
        <form>
          <app-schedule (scheduleSelected)="onSelectSchedule($event)"></app-schedule>
        </form>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Select Appointment</ng-template>
        <app-appointment (callback)="appointmentCallback($event)" *ngIf="stepIndex === 1"> </app-appointment>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
  <div *ngIf="allSchedules && allSchedules.length === 1">
    <h4>Select Appointment ({{ (selectedSchedule | async)!.scheduleName }})</h4>
    <app-appointment (callback)="appointmentCallback($event)"> </app-appointment>
  </div>
</div>
<app-appointment-confirm *ngIf="showThankYouPage" (callback)="thanksPageCallback($event)"></app-appointment-confirm>
