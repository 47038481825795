import { Component, OnDestroy, Input, OnChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { FADE_IN } from '../../util/animations/animation';
import { ConfirmPopupComponent } from '../confirm-popup/confirm-popup.component';
import { Observable, Subscription } from 'rxjs';
import { DateTime } from 'luxon';
import { Store } from '@ngrx/store';
import { AppState } from '../../state';
import {
  AppointmentEffects,
  AppointmentsQuery,
  Appointment,
  selectAllAppointmentsWithSchedule,
} from '../../state/appointment';
import { UserEffects, UsersQuery } from '../../state/user';
import { distinctUntilChanged, filter, tap, debounceTime } from 'rxjs/operators';
import { ScheduleEffects } from 'src/app/state/schedules';
import { getWidgetInputs, WidgetInput } from '../../state/widget';
import { getScheduleIdForAppointment } from '../../util/helper';
@Component({
  selector: 'app-appointment-list',
  templateUrl: './appointment-list.component.html',
  styleUrls: ['./appointment-list.component.scss'],
  animations: [FADE_IN],
})
export class AppointmentListComponent implements OnDestroy, OnChanges {
  @Input() pid: string | null | undefined;
  appointments: Observable<Appointment[]>;
  isLoading$: Observable<boolean>;
  authSubscriber: Subscription;
  selectedAppointment: Appointment | null;
  widgetInputs$: Observable<WidgetInput>;
  DateTime = DateTime;

  constructor(
    private toastr: ToastrService,
    public dialog: MatDialog,
    private store: Store<AppState>,
    private appointmentEffects: AppointmentEffects,
    private auth: UserEffects,
    private scheduleEffect: ScheduleEffects
  ) {
    this.store.select(UsersQuery.getUid).pipe(
      filter((uid) => !!uid),
      tap((uid) => this.appointmentEffects.query(uid))
    );
    this.authSubscriber = this.auth.userId$.subscribe((uid) => {
      if (uid) {
        this.appointmentEffects.query(uid);
      }
    });

    this.appointments = this.store.select(selectAllAppointmentsWithSchedule).pipe(debounceTime(500));

    this.appointmentEffects.error$.pipe(
      filter((e) => !!e),
      distinctUntilChanged(),
      tap((error) =>
        this.toastr.warning(error?.toString() || 'Unknown error', 'Appointment', {
          progressBar: true,
          timeOut: 3000,
        })
      )
    );
    this.widgetInputs$ = this.store.select(getWidgetInputs);
    this.isLoading$ = this.store.select(AppointmentsQuery.isLoading);
  }

  ngOnChanges() {
    if (this.pid) {
      this.appointmentEffects.query(`Patient/${this.pid}`);
    }
  }
  cancelAppointment(appointment: Appointment) {
    const payload = {
      id: appointment.id,
      status: 'cancelled',
    };
    this.appointmentEffects.update(payload);
  }

  cancelAppointmentPopup(appointment: Appointment): void {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      width: '450px',
      disableClose: true,
      data: {
        title: { text: 'Confirmation' },
        subTitle: { text: `Are you sure you want to cancel this appointment?` },
        cancelBtn: { text: 'No' },
        okBtn: { text: 'Yes' },
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.confirm) {
        this.cancelAppointment(appointment);
      }
    });
  }

  rescheduleAppointment(appointment: Appointment): void {
    this.selectedAppointment = appointment;
    if (appointment.schedule) {
      this.scheduleEffect.select(appointment.schedule);
    } else {
      const sid = getScheduleIdForAppointment(appointment);
      this.scheduleEffect.select(sid);
    }
  }

  bookAppointment(selectedSlot: any) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      width: '450px',
      disableClose: true,
      data: {
        title: { text: 'Confirmation' },
        subTitle: { text: `Are you sure you want to reschedule the appointment?` },
        cancelBtn: { text: 'No' },
        okBtn: { text: 'Yes' },
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.confirm) {
        const payload: Appointment = {
          ...this.selectedAppointment!,
          slot: [
            {
              reference: `Slot/${selectedSlot.id}`,
            },
          ],
        };
        this.appointmentEffects.update(payload);
      } else {
        this.onBack();
      }
    });
  }

  onBack() {
    this.selectedAppointment = null;
  }

  ngOnDestroy(): void {
    this.authSubscriber.unsubscribe();
  }

  appointmentCallback(event: any) {
    if (event && event.type === 'appointment-success') {
      this.onBack();
    } else if (event && event.type === 'book-appointment') {
      this.bookAppointment(event.slot);
    }
  }
}
