import {
  Component,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  ViewEncapsulation,
  OnChanges,
  OnInit,
  OnDestroy,
} from '@angular/core';
import isString from 'lodash-es/isString';
import { Store } from '@ngrx/store';
import { AppState } from './state/state';
import { WidgetSettings, Added, getWidgetInputs, WidgetInput, defaultSettings } from './state/widget';
import { Observable, Subscription } from 'rxjs';
import { FADE_IN } from './util/animations/animation';
import * as userActions from './state/user/user.actions';
import { selectAllAppointments } from './state/appointment';
import { debounceTime } from 'rxjs/operators';
import { StopLoader } from './state/user';
const DEBOUNCE_TIME = 1000;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [FADE_IN],
})
export class AppComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() h_sid?: string | null;
  @Input() he_pid?: string | null;
  @Input() pid?: string | null;
  @Input() he_uaid?: string | null;
  @Input() styles: any;
  @Input() settings: WidgetSettings;
  @Output() public callback = new EventEmitter();
  @Output() public count = new EventEmitter();

  widgetInputs$: Observable<WidgetInput>;
  appointmentsSubscription: Subscription;

  constructor(private store: Store<AppState>) {
    this.widgetInputs$ = this.store.select(getWidgetInputs);

    this.appointmentsSubscription = this.store
      .select(selectAllAppointments)
      .pipe(debounceTime(DEBOUNCE_TIME))
      .subscribe((appointments) => {
        const bookedAppointments = (appointments && appointments.filter((x) => x.status !== 'cancelled')) || [];
        this.count.emit(bookedAppointments.length);
      });
  }

  ngOnChanges(): void {
    this.ngOnInit();
  }

  ngOnInit(): void {
    this.parseInputParams();
    this.store.dispatch(new userActions.Check());

    if (this.settings) {
      this.store.dispatch(new Added({ settings: { ...defaultSettings, ...this.settings } }));
    }
    if (this.styles) {
      this.store.dispatch(new Added({ styles: this.styles }));
    }
  }

  ngAfterViewInit(): void {
    this.callback.emit({ event: 'load', message: 'Widget loaded successfully' });
  }

  parseInputParams() {
    if (isString(this.styles)) {
      try {
        this.styles = JSON.parse(this.styles);
      } catch (e) {
        console.error('error while parsing "styles" as JSON', e);
      }
    }

    if (isString(this.settings)) {
      this.settings = JSON.parse(this.settings);
    }

    const params = new URLSearchParams(window.location.search);
    if (!this.he_pid) {
      if (params.has('he_pid')) {
        this.he_pid = params.get('he_pid');
      } else {
        this.he_pid = sessionStorage.getItem('he_pid');
      }
    }
    if (!this.pid) {
      if (params.has('pid')) {
        this.pid = params.get('pid');
      } else {
        this.pid = sessionStorage.getItem('pid');
      }
    }
    if (!this.h_sid) {
      if (params.has('h_sid')) {
        this.h_sid = params.get('h_sid');
      } else {
        this.h_sid = sessionStorage.getItem('h_sid');
      }
    }
    if (!this.he_uaid) {
      if (params.has('he_uaid')) {
        this.he_uaid = params.get('he_uaid');
      } else {
        this.he_uaid = sessionStorage.getItem('he_uaid');
      }
    }
    if (this.he_pid) {
      sessionStorage.setItem('he_pid', this.he_pid);
    }
    if (this.pid) {
      sessionStorage.setItem('pid', this.pid);
      this.store.dispatch(new StopLoader());
    }
    if (this.he_uaid) {
      sessionStorage.setItem('he_uaid', this.he_uaid);
    }
    if (this.h_sid) {
      sessionStorage.setItem('h_sid', this.h_sid);
    }
    const payload = { pid: this.pid, he_pid: this.he_pid, he_uaid: this.he_uaid, h_sid: this.h_sid };
    this.store.dispatch(new Added(payload));
  }

  ngOnDestroy() {
    this.appointmentsSubscription.unsubscribe();
  }

  appointmentCallback(event: any) {
    this.callback.emit(event);
  }
}
