import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { environment } from '../../../environments/environment';
import { createFeatureSelector, createSelector, MemoizedSelector, DefaultProjectorFn } from '@ngrx/store';
import { AppState } from '../state';

export interface WidgetInput {
  h_sid?: string | null;
  he_pid?: string | null;
  pid?: string | null;
  he_uaid?: string | null;
  styles?: any;
  settings?: WidgetSettings;
}

export interface WidgetSettings {
  showTitle?: boolean;
  showLogo?: boolean;
  title?: string;
  logoUrl?: string;
  mode?: string;
  cardPadding?: boolean;
  boxShodow?: boolean;
}

export const widgetInputFeatureKey = 'widgetInput';

export const widgetInputAdapter = createEntityAdapter<WidgetInput>();

export type State = EntityState<WidgetInput>;

export const defaultSettings = {
  showTitle: true,
  showLogo: true,
  title: 'Appointments',
  logoUrl: `${environment.tenant.logo}`,
  mode: 'full',
  cardPadding: true,
  boxShodow: true,
};

export const initialState: State = widgetInputAdapter.getInitialState({
  styles: { width: 80 },
  settings: defaultSettings,
});
export const getWidgetInputState = createFeatureSelector<AppState, State>(widgetInputFeatureKey);
export const getWidgetInputs = createSelector(getWidgetInputState, (state) => state) as MemoizedSelector<
  AppState,
  any,
  DefaultProjectorFn<any>
>;

export const { selectIds, selectAll: selectAllInputs, selectEntities, selectTotal } = widgetInputAdapter.getSelectors(
  getWidgetInputState
);
