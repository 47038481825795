<div *ngIf="showListComponent && !showAppointmentComponent" @fadeIn>
  <div
    *ngIf="(auth.isLoading$ | async) === false"
    class="m-b-20"
    fxLayout="row wrap"
    fxLayoutAlign="end start"
    fxLayoutGap.gt-xs="20px"
  >
    <button fxFlex.lt-sm="100" class="m-b-10" mat-raised-button color="primary" (click)="bookAppointment()">
      Schedule Appointment
    </button>
    <div fxFlex.lt-sm="100" *ngIf="!(widgetInputs$ | async)!.pid">
      <button
        fxFlex.lt-sm="100"
        *ngIf="(auth.isAuthenticated$ | async) === false"
        (click)="loginWithPopup()"
        mat-raised-button
        color="primary"
      >
        My Appointments
      </button>
      <button
        fxFlex.lt-sm="100"
        *ngIf="auth.isAuthenticated$ | async"
        mat-raised-button
        color="primary"
        (click)="logout()"
      >
        Logout
      </button>
    </div>
  </div>
  <div
    *ngIf="auth.isLoading$ | async"
    class="m-b-20"
    fxLayout="row wrap"
    fxLayoutAlign="end start"
    fxLayoutGap.gt-xs="20px"
  >
    <div class="skeleton-loader m-b-10" fxFlex="175px" fxFlex.lt-sm="100" style="height: 35px"></div>
    <div class="skeleton-loader" fxFlex="143px" fxFlex.lt-sm="100" style="height: 35px"></div>
  </div>
  <div fxLayout="row wrap" *ngIf="(auth.isAuthenticated$ | async) || (widgetInputs$ | async)!.pid">
    <div fxFlex>
      <app-appointment-list [pid]="(widgetInputs$ | async)!.pid"></app-appointment-list>
    </div>
  </div>
</div>
<div *ngIf="showAppointmentComponent && !showListComponent" @fadeIn>
  <div>
    <button mat-icon-button (click)="onBack()">
      <mat-icon class="m-r-10">keyboard_arrow_left</mat-icon>
      Back
    </button>
  </div>
  <div>
    <app-new-mode (callback)="newModeCallback($event)"></app-new-mode>
  </div>
</div>
