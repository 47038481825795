import { Action } from '@ngrx/store';
import { WidgetInput } from './widget.model';

export const SELECT = '[WidgetInput] select';
export const ADDED = '[WidgetInput] added';
export const MODIFIED = '[WidgetInput] modified';

export class Added implements Action {
  readonly type = ADDED;
  constructor(public payload: WidgetInput) {}
}
export class Modified implements Action {
  readonly type = MODIFIED;
  constructor(public payload: any) {}
}
export class Select implements Action {
  readonly type = SELECT;
}

export type All = Added | Modified | Select;
