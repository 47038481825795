import { Component, OnInit, ChangeDetectorRef, OnDestroy, Output, EventEmitter } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { ScheduleEffects, Schedule, getSelectedSchedule, selectAllSchedules } from '../../state/schedules';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../state/state';
import { getWidgetInputs, WidgetInput } from '../../state/widget';
import { debounceTime } from 'rxjs/operators';
const DEBOUNCE_TIME = 500;
import { AppointmentEffects, Appointment } from '../../state/appointment';
@Component({
  selector: 'app-new-mode',
  templateUrl: './new-mode.component.html',
  styleUrls: ['./new-mode.component.scss'],
})
export class NewModeComponent implements OnInit, OnDestroy {
  allSchedules: Schedule[] = [];
  selectedSchedule: Observable<Schedule | null | undefined>;
  inputs: WidgetInput;
  h_sid: string[] | undefined;
  stepIndex = 0;
  widgetInputSubscription: Subscription;

  mobileQuery: MediaQueryList;

  allSchedulesSubscription: Subscription;
  @Output() callback = new EventEmitter();
  showThankYouPage = false;

  constructor(
    private scheduleEffect: ScheduleEffects,
    private store: Store<AppState>,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public appointmentEffects: AppointmentEffects
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 960px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.allSchedulesSubscription = this.store
      .select(selectAllSchedules)
      .pipe(debounceTime(DEBOUNCE_TIME))
      .subscribe((schedules) => {
        this.allSchedules = schedules;
        if (this.allSchedules.length === 1) {
          this.scheduleEffect.select(this.allSchedules[0]);
        }
      });
    this.selectedSchedule = this.store.select(getSelectedSchedule);
    this.widgetInputSubscription = this.store.select(getWidgetInputs).subscribe((res) => {
      this.inputs = res;
    });
  }

  private _mobileQueryListener: () => void;

  ngOnInit(): void {
    this.h_sid = this.inputs.h_sid?.split(',');
    this.scheduleEffect.query(this.h_sid);
  }

  ngOnDestroy() {
    this.allSchedulesSubscription.unsubscribe();
    this.widgetInputSubscription.unsubscribe();
    this.scheduleEffect.cancelQuery();
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  onSelectSchedule(schedule: Schedule) {
    this.scheduleEffect.select(schedule);
    this.stepIndex = 0;
    setTimeout(() => {
      this.stepIndex = 1;
    }, 0);
  }

  appointmentCallback(event: any) {
    if (event && event.type === 'book-appointment') {
      this.bookAppointment(event.slot);
    }
    if (event && event.type === 'appointment-success') {
      this.showThankYouPage = true;
    }
  }

  bookAppointment(selectedSlot: any) {
    const payload: Appointment = {
      participant: [
        {
          status: 'accepted',
          actor: {
            id: this.inputs.he_pid!,
          },
        },
      ],
      uniqueApptId: this.inputs.he_uaid!,
      status: 'booked',
      slot: [
        {
          reference: `Slot/${selectedSlot.id}`,
        },
      ],
    };
    this.appointmentEffects.create(payload);
  }

  thanksPageCallback(event: any) {
    this.callback.emit({ type: 'appointment-success' });
  }
}
