import { Appointment as App, Schedule } from '@carecognitics/fhir-resources';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../state';
import { HttpErrorResponse } from '@angular/common/http';
import { getSchedulesState } from '../schedules';
import { getScheduleIdForAppointment } from '../../util/helper';

export interface Appointment extends App {
  schedule?: Schedule | null | undefined;
}

export const appointmentFeatureKey = 'appointment';

export interface State extends EntityState<Appointment> {
  loading: boolean;
  error: HttpErrorResponse | string | null | undefined;
  beforeUpdate?: Appointment | null | undefined;
}

export const appointmentAdapter = createEntityAdapter<Appointment>({
  sortComparer: (a, b) => b.start?.localeCompare(a.start!) || 0, // sort descending by start
});

export const initialState: State = appointmentAdapter.getInitialState({
  loading: false,
  error: null,
  beforeUpdate: null,
});

export const getAppointmentsState = createFeatureSelector<AppState, State>(appointmentFeatureKey);
export const getSelectedAppointment = createSelector(getAppointmentsState, (state) => state);

export const {
  selectIds,
  selectAll: selectAllAppointments,
  selectEntities,
  selectTotal,
} = appointmentAdapter.getSelectors(getAppointmentsState);

export const selectAllAppointmentsWithSchedule = createSelector(
  selectAllAppointments,
  getSchedulesState,
  (appointments: Appointment[], scheduleState) =>
    appointments.map((app) => ({
      ...app,
      schedule: scheduleState.entities[getScheduleIdForAppointment(app)],
    }))
);
