export interface IUser {
  authenticated: boolean;
  loading: boolean;
  uid: string;
  scope: string;
  name: string;
  given_name?: string;
  family_name?: string;
  gender?: string;
  birthdate?: string;
  email?: string;
  phone_number?: string;
  error?: string;
}

export class User implements IUser {
  loading = true;
  authenticated = false;
  constructor(public uid: string, public name: string, public scope = '') {}
}
