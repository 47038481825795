<div fxLayout="row wrap" fxLayoutAlign="center center">
  <div class="popup-title" mat-dialog-title>{{ popup.title.text }}</div>
</div>
<div fxLayout="row wrap" mat-dialog-content fxLayoutAlign="center center">
  <p>{{ popup.subTitle?.text }}</p>
</div>
<div fxLayout="row wrap" mat-dialog-actions fxLayoutAlign="center center">
  <button
    *ngIf="popup.okBtn"
    mat-raised-button
    color="primary"
    [mat-dialog-close]="{ confirm: true }"
    cdkFocusInitial
    class="popup-okBtn"
  >
    {{ popup.okBtn.text }}
  </button>
  <button *ngIf="popup.cancelBtn" mat-raised-button color="secondary" (click)="onNoClick()">
    {{ popup.cancelBtn.text }}
  </button>
</div>
