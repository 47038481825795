/* eslint-disable no-constant-condition, @typescript-eslint/ban-ts-comment */
// import { CacheLocation } from '@auth0/auth0-spa-js';

export const environment = {
  // @ts-ignore
  production: 'qa' === 'production',
  tenant: {
    logo: 'https://www.carecognitics.com/wp-content/uploads/2018/10/icon.png',
    name: 'OneHealth',
    imageBaseUrl: 'assets/images/',
  },
  auth0Config: {
    clientId: 'vwCUj2KcRcYLXxOOyLcqE31oZ-dqc9BfjtBvQAQRBto',
    domain: 'patient-auth-service.onehealth.test.mycareplan.health',
    redirectUri: 'https://onehealth.test.mycareplan.health',
    audience: 'https://identitytoolkit.googleapis.com/google.identity.identitytoolkit.v1.IdentityToolkit',
    issuer: 'patient-auth-service@cc-deploy-onehealth-dev.iam.gserviceaccount.com',
    // @ts-ignore
    ...('' !== '' ? { scope: '' } : {}),
  },
  firebaseConfig: {
    apiKey: 'AIzaSyDSheQDE33m1ACWWH93VC8fUn1RN3cLTCw',
    authDomain: 'cc-deploy-onehealth-dev.firebaseapp.com',
    databaseURL: 'https://cc-deploy-onehealth-dev.firebaseio.com',
    projectId: 'cc-deploy-onehealth-dev',
    storageBucket: 'cc-deploy-onehealth-dev.appspot.com',
    messagingSenderId: '71657680916',
    appId: '1:71657680916:web:5a092f8ed4c34d24773701',
    measurementId: 'G-QCDZ6QN1JC',
  },
  services: {
    appointment: `https://appointment-service.onehealth.test.mycareplan.health`,
    communication: `https://communication-service.onehealth.test.mycareplan.health`,
    patientAuth: `https://patient-auth-service.onehealth.test.mycareplan.health`,
  },
  firestore: {
    unsubscribeDelay: 30000,
  },
};

// @ts-ignore
// if ('qa' !== 'production') {
//   require('zone.js/dist/zone-error');
// }
/* eslint-enable no-constant-condition, @typescript-eslint/ban-ts-comment */
