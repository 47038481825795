import { Component, EventEmitter, Output } from '@angular/core';
import { FADE_IN } from '../../util/animations/animation';

@Component({
  selector: 'app-appointment-confirm',
  templateUrl: './appointment-confirm.component.html',
  styleUrls: ['./appointment-confirm.component.scss'],
  animations: [FADE_IN],
})
export class AppointmentConfirmComponent {
  @Output() callback = new EventEmitter();
}
