import { NgModule } from '@angular/core';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';

import { environment } from '../../environments/environment';
import { UserEffects, userReducer } from './user';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AppEffects } from '../app.effects';
import { ScheduleEffects, schedulesReducer } from './schedules';
import { SlotEffects, slotsReducer } from './slots';
import { widgetInputReducer } from './widget';
import { AppointmentEffects, appointmentsReducer } from './appointment';

declare global {
  interface Window {
    firebaseAppId?: string;
    firebaseMeasurementId?: string;
  }
}

const firebaseConfig = environment.firebaseConfig;
// since we need this attribute on initialization, passing it via a component property is not possible
// so we instead use the global window
if (window.firebaseAppId) {
  firebaseConfig.appId = window.firebaseAppId;
}
if (window.firebaseMeasurementId) {
  firebaseConfig.measurementId = window.firebaseMeasurementId;
}

@NgModule({
  imports: [
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireAnalyticsModule,
    StoreDevtoolsModule.instrument({
      logOnly: environment.production,
      maxAge: 25,
    }),
    StoreModule.forRoot({
      user: userReducer as any,
      schedules: schedulesReducer as any,
      slots: slotsReducer as any,
      widgetInput: widgetInputReducer as any,
      appointment: appointmentsReducer as any,
    }),
    EffectsModule.forRoot([AppEffects, UserEffects, ScheduleEffects, SlotEffects, AppointmentEffects]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
  ],
  providers: [ScheduleEffects, AppointmentEffects, UserEffects, ScreenTrackingService, UserTrackingService],
})
export class AppStateModule {}
